import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

// eslint-disable-next-line
import { langDefault, langAvailable, segments } from '@/inc/app.config'
import View from '@/views/View.vue'

// Format segments to (a|b)
// eslint-disable-next-line
const formatSegment = (key: string): string =>
  Array.isArray(segments[key])
    ? `(${(segments[key] as string[]).join('|')})`
    : (segments[key] as string)

let routes: RouteConfig[] = [
  // Growth expert details
  {
    path: '/expertises/:segment(growth)/conseillers/:slug/',
    name: 'GrowthDetails',
    component: () =>
      import(
        /* webpackChunkName: 'v-catalog-details' */ '@/views/CatalogDetails.vue'
      ),
    meta: {
      ssr: true,
      segment: 'growth',
      endpoint: 'experts-growth',
    },
  },
  // Growth expert list
  {
    path: '/expertises/:segment(growth)/conseillers/',
    name: 'GrowthList',
    component: () =>
      import(
        /* webpackChunkName: 'v-catalog-list' */ '@/views/CatalogList.vue'
      ),
    meta: {
      ssr: true,
      segment: 'growth',
    },
  },
  // CessionAcquisition expert details
  {
    path: '/expertises/:segment(cession-acquisition)/conseillers/:slug/',
    name: 'CessionAcquisitionDetails',
    component: () =>
      import(
        /* webpackChunkName: 'v-catalog-details' */ '@/views/CatalogDetails.vue'
      ),
    meta: {
      ssr: true,
      segment: 'cession-acquisition',
      endpoint: 'experts-cession-acquisition',
    },
  },
  // CessionAcquisition expert list
  {
    path: '/expertises/:segment(cession-acquisition)/conseillers/',
    name: 'CessionAcquisitionList',
    component: () =>
      import(
        /* webpackChunkName: 'v-catalog-list' */ '@/views/CatalogList.vue'
      ),
    meta: {
      ssr: true,
      segment: 'cession-acquisition',
    },
  },
  // CessionAcquisition pme catalog
  {
    path: '/expertises/:segment(cession-acquisition)/entreprises/',
    name: 'CessionAcquisitionPme',
    component: () =>
      import(
        /* webpackChunkName: 'v-cession-acquisition-pme' */ '@/views/pages/CessionAcquisitionPme.vue'
      ),
    meta: {
      segment: 'cession-acquisition',
    },
  },
  // Catch all and redirect to sowalfin.be
  {
    path: '/*',
    name: 'All',
    meta: {
      ssr: false,
    },
    beforeEnter() {
      location.href = 'https://www.sowalfin.be/'
    },
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  const initialRoutes = [...routes]
  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: View as VueConstructor<Vue>,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: true,
      },
      children: initialRoutes
        .filter(r => r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
  ]

  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
}

export { routes }
