






































import { defineComponent, ref, computed, onMounted } from '@vue/composition-api'
import gsap from 'gsap'

export default defineComponent({
  name: 'GVideoModal',
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const modal = ref<HTMLElement | null>(null)
    const player = ref<HTMLElement | null>(null)
    const ratio = 16 / 9
    const viewportWidth = ref(0)
    const viewportHeight = ref(0)

    const onResize = (width: number, height: number) => {
      viewportWidth.value = width
      viewportHeight.value = height
    }

    const playerWidth = computed(() => {
      const potentialHeight = viewportWidth.value * (9 / 16)
      const maxHeight = viewportHeight.value * 0.7
      const maxWidth = 1200

      if (potentialHeight > maxHeight) {
        return maxHeight * ratio > maxWidth ? maxWidth : maxHeight * ratio
      }

      return viewportWidth.value - 50 > maxWidth
        ? maxWidth
        : viewportWidth.value - 50
    })

    const close = (e?: KeyboardEvent) => {
      if (e?.key && e?.key !== 'Escape') {
        return
      }

      const tl = gsap.timeline({
        onComplete: () => {
          ctx.emit('video:close')
        },
      })

      tl.to(modal.value!.querySelector('.video-modal__content'), {
        opacity: 0,
        y: 30,
        duration: 0.25,
      })

      tl.to(modal.value!.querySelector('.video-modal__layer'), {
        opacity: 0,
        duration: 0.25,
      })

      window.removeEventListener('keyup', close)
    }

    onMounted(() => {
      viewportWidth.value = window.innerWidth
      viewportHeight.value = window.innerHeight

      modal.value!.querySelector('button')?.focus()
      window.addEventListener('keyup', close)

      const tl = gsap.timeline({
        onComplete: () => {
          modal.value!.focus()
        },
      })

      tl.from(modal.value!.querySelector('.video-modal__layer'), {
        opacity: 0,
        duration: 0.5,
      })

      tl.from(modal.value!.querySelector('.video-modal__content'), {
        opacity: 0,
        y: 30,
        duration: 0.5,
      })
    })

    return {
      modal,
      player,
      ratio,
      close,
      onResize,
      playerWidth,
    }
  },
})
