
















import { defineComponent } from '@vue/composition-api'
import { List } from '@/inc/types'

export default defineComponent({
  name: 'GList',
  components: {},
  props: {
    list: {
      type: Array as () => List,
      default() {
        return [] as List
      },
    },
  },
  setup() {
    return {}
  },
})
