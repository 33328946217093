import axios from 'axios'
import queryString from 'query-string'
import { intersection } from 'rambda'
import VueRouter, { Route } from 'vue-router'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'

import { getApiUrl } from '@/inc/app.config'
import {
  CatalogState,
  RootState,
  CatalogCategory,
  CatalogItem,
  CatalogFilter,
  CatalogProfile,
  FilterComplex,
  FilterSimple,
  FilterComplexItem,
  CessionAcquisitionProfile,
  CatalogLabelSlug,
  CatalogView,
} from '@/inc/types'

interface QueryStringPayload {
  router?: VueRouter
  query?: Route['query']
}

interface FilterPayload extends QueryStringPayload {
  key: string
  itemSlug?: string
  selection: string[] | 'all' | 'none'
}

interface ViewPayload extends QueryStringPayload {
  value?: CatalogView
}

interface ProfilePayload extends QueryStringPayload {
  value: CessionAcquisitionProfile
}

interface VoucherPayload extends QueryStringPayload {
  value: boolean
}

interface CompanySizePayload extends QueryStringPayload {
  value: string
}

interface KeywordsPayload extends QueryStringPayload {
  value: string
}

interface CategoriesPayload extends QueryStringPayload {
  value?: string[]
}

interface ResetAllPayload {
  segment: string
  router: VueRouter
}

const updateHistory = (router?: VueRouter, query?: Route['query']) => {
  if (router && router.$$type === 'client' && query) {
    // console.log('updateHistory :: query -> ', query)
    const existingQuery = queryString.parse(window.location.search)
    const mergedQuery = Object.keys(query).length
      ? { ...existingQuery, ...query }
      : {}
    const { href } = router.resolve({ query: mergedQuery })
    // console.log('updateHistory :: href -> ', href)

    const to = `
            ${window.location.protocol}//${window.location.host}${href}`
    window.history.replaceState({ path: to }, '', to)
  }
}

export const defaultValues = {
  segment: 'growth',
  profile: {
    /* eslint-disable quote-props */
    growth: null,
    'cession-acquisition': 'buyers',
    /* eslint-enable quote-props */
  },
  hasVoucher: false,
  keywords: '',
  selectedKeywords: [],
  view: 'list',
  companySize: '',
}

const state: CatalogState = {
  isLoading: true,
  allCatalog: [],
  filteredCatalog: [],
  filters: null,
  profile: defaultValues.profile.growth,
  hasVoucher: defaultValues.hasVoucher,
  keywords: defaultValues.keywords,
  selectedKeywords: defaultValues.selectedKeywords,
  view: defaultValues.view as CatalogView,
  hasMarkers: false,
  companySize: defaultValues.companySize,
}

const getters: GetterTree<CatalogState, RootState> = {
  isLoading: state => state.isLoading,
  catalog: state => state.filteredCatalog,
  filterByKey: state => (key: string) => state.filters?.[key],
  // Based on segment and profile
  // returns categories (growth) or sellers / buyers (cession-acquisition)
  categories: state => {
    if (state.filters === null) {
      return []
    }

    if (state.profile) {
      return state.filters[state.profile]
    }

    return state.filters.categories
  },
  selectedCategories: (state, getters) => {
    if (getters.categories?.kind === 'simple') {
      return getters.categories.selected
    }

    return getters.categories.items
      .filter(item => item.selected.length > 0)
      .map(item => item.slug)
  },
}

const mutations: MutationTree<CatalogState> = {
  SET_PROFILE(state, profile: CatalogProfile) {
    if (profile) {
      state.profile = profile
    }
  },
  SET_CATALOG(state, entities: CatalogItem[]) {
    if (entities) {
      state.allCatalog = entities
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SET_FILTERS(state, elements: any) {
    if (elements) {
      // Add some extra properties:
      // - kind: simple vs complex
      // - selected: for v-model
      // - if complex, max value for subitems to compare with selected (all selected)
      const formatted = elements.reduce((acc: CatalogFilter, element) => {
        const { key } = element
        const filter = {
          ...element,
        } as CatalogFilter
        const isComplex = element.items.some(item => item.items)

        if (isComplex) {
          // Hack pour remettre la structure des filtres d'équerre
          // et remonter d'un niveau les catégories des cession-acquisition…
          ;(filter as FilterComplex).items = element.items.map(item => ({
            ...item,
            max: item.items.length,
            selected: [],
          }))
          filter.kind = 'complex'
        } else {
          ;(filter as FilterSimple).selected = []
          filter.kind = 'simple'
        }

        acc[key] = filter

        return acc
      }, {} as CatalogFilter)

      state.filters = formatted
    }
  },
  SET_MARKERS(state, status: boolean) {
    state.hasMarkers = status
  },
  UPDATE_VOUCHER(state, payload: boolean) {
    state.hasVoucher = payload
  },
  UPDATE_COMPANY_SIZE(state, payload: string) {
    state.companySize = payload
  },
  UPDATE_PROFILE(state, payload: CessionAcquisitionProfile) {
    state.profile = payload
  },
  UPDATE_FILTER(state, payload: FilterPayload) {
    if (payload && state.filters) {
      const { key, itemSlug, selection } = payload
      const items: (FilterSimple | FilterComplexItem)[] = []

      const filter = state.filters[key] as FilterSimple | FilterComplex

      // Easy peasy…
      if (filter.kind === 'simple') {
        items.push(filter)
      }

      if (filter.kind === 'complex') {
        // One single subItem
        if (itemSlug) {
          const subItem = filter.items.find(
            item => item.slug === itemSlug
          ) as FilterComplexItem

          subItem && items.push(subItem)
        } else {
          // All subItems (allow to reset or check all)
          items.push(...filter.items)
        }
      }

      items.forEach(item => {
        if (selection === 'all') {
          item.selected = item.items.map(i => i.slug)
        } else if (selection === 'none') {
          item.selected = []
        } else {
          item.selected = selection
        }
      })
    }
  },
  UPDATE_KEYWORDS(state, payload: string) {
    state.keywords = payload.toLowerCase()
  },
  SET_VIEW(state, view: CatalogView) {
    state.view = view
  },
  FILTER(state) {
    console.time('filter')
    // Start from high impact filters…
    let filteredCatalog = [...state.allCatalog]

    // Marker reset
    if (state.hasMarkers) {
      filteredCatalog.forEach(item => {
        item.marker?.setVisible(false)
      })
    }

    // Company Size
    const hasCompanySize = item =>
      item.typeOfCompanies.find(el => el.slug === state.companySize)

    if (state.companySize) {
      filteredCatalog = filteredCatalog.filter(
        item => hasCompanySize(item) !== undefined
      )
    }

    // Voucher
    if (state.hasVoucher) {
      filteredCatalog = filteredCatalog.filter(item => item.hasVoucher)
    }

    // Profile (cession-acquisition only)
    if (state.profile) {
      filteredCatalog = filteredCatalog.filter(item => {
        const key = state.profile === 'buyers' ? 'isBuyer' : 'isSeller'

        return item[key]
      })
    }

    // Filters
    if (state.filters) {
      // Get simple / complex filters with something selected
      const { simple: simpleFilters, complex: complexFilters } = Object.keys(
        state.filters
      ).reduce(
        (acc, key) => {
          const filter = state.filters![key] as FilterSimple | FilterComplex
          const { kind } = filter

          if (
            kind === 'simple' &&
            (filter as FilterSimple).selected.length > 0
          ) {
            acc.simple.push(key)
          }

          if (
            kind === 'complex' &&
            (filter as FilterComplex).items.some(
              item => item.selected.length > 0
            )
          ) {
            acc.complex.push(key)
          }

          return acc
        },
        { simple: [], complex: [] } as { simple: string[]; complex: string[] }
      )

      if (simpleFilters.length > 0) {
        simpleFilters.forEach(filterKey => {
          filteredCatalog = filteredCatalog.filter(item => {
            const { selected } = state.filters![filterKey] as FilterSimple
            const items = (item[filterKey] as CatalogLabelSlug[]).map(
              el => el.slug
            )

            return intersection(selected, items).length > 0
          })
        })
      }

      if (complexFilters.length > 0) {
        complexFilters.forEach(filterKey => {
          const filter = state.filters![filterKey] as FilterComplex
          const filterItems = filter.items.filter(
            item => item.selected.length > 0
          )

          filteredCatalog = filteredCatalog.filter(item => {
            let isSelected = false

            if (!item[filterKey]) {
              return false
            }

            // Top level
            const items = item[filterKey] as CatalogCategory[]
            const itemSlugs = items.map(item => item.slug)

            filterItems.forEach(filterItem => {
              const { slug: filterSlug, selected, max } = filterItem

              if (itemSlugs.includes(filterSlug)) {
                // All subcategories selected
                if (selected.length === max) {
                  isSelected = true
                } else {
                  // Some subcategories selected
                  const subSlugs = (items.find(
                    item => item.slug === filterSlug
                  ) as CatalogCategory).subCategories.map(sub => sub.slug)

                  if (intersection(selected, subSlugs).length > 0) {
                    isSelected = true
                  }
                }
              }
            })

            return isSelected
          })
        })
      }
    }

    if (state.keywords.length > 2) {
      // TODO: manage accented letters
      filteredCatalog = filteredCatalog.filter(
        item =>
          item.expertName.toLowerCase().includes(state.keywords) ||
          item.companyName.toLowerCase().includes(state.keywords) ||
          item.keywords?.toLowerCase().includes(state.keywords)
      )
    }

    // Marker visibility
    if (state.hasMarkers) {
      filteredCatalog.forEach(item => {
        if (item.marker) {
          item.marker?.setVisible(true)
        }
      })
    }

    state.isLoading = false
    state.filteredCatalog = filteredCatalog
    console.timeEnd('filter')
  },
}

const actions: ActionTree<CatalogState, RootState> = {
  async fetchAll(
    { commit, state, dispatch, getters },
    { value, query }: { value: string; query: Route['query'] }
  ) {
    try {
      const apiUrl = getApiUrl()
      const segment = value || defaultValues.segment
      const catalogRequest = axios.get(`${apiUrl}/experts-${segment}/`)
      const filtersRequest = axios.get(`${apiUrl}/filters/${segment}/`)

      const [expertsResponse, filtersResponse] = await Promise.all([
        catalogRequest,
        filtersRequest,
      ])

      commit('SET_MARKERS', false)
      commit('SET_PROFILE', segment === 'growth' ? null : 'buyers')
      commit('SET_CATALOG', expertsResponse.data.content.entities)
      commit('SET_FILTERS', filtersResponse.data.content.elements)

      // TODO: Check segment & query params before init

      if (query) {
        // Init from query string
        const {
          profile,
          view,
          voucher,
          keywords,
          categories,
          sectors,
          regions,
          profession,
        } = query

        profile && commit('SET_PROFILE', profile)
        view && commit('SET_VIEW', view)
        voucher && commit('UPDATE_VOUCHER', voucher === 'true')
        keywords && commit('UPDATE_KEYWORDS', keywords)

        if (categories) {
          if (state.profile) {
            // CessionAcquisition = 'buyers' | 'sellers' (aka profile) keys
            commit('UPDATE_FILTER', {
              key: state.profile,
              selection: (categories as string).split(','),
            })
          } else {
            dispatch('updateComplexCategories', {
              slugs: (categories as string).split(','),
              items: getters.categories.items,
            })
          }
        }

        profession &&
          commit('UPDATE_FILTER', {
            key: 'profession',
            selection: (profession as string).split(','),
          })

        sectors &&
          commit('UPDATE_FILTER', {
            key: 'sectors',
            selection: (sectors as string).split(','),
          })

        regions &&
          commit('UPDATE_FILTER', {
            key: 'regions',
            selection: (regions as string).split(','),
          })
      }

      commit('FILTER')
    } catch (error) {
      console.error('[fetchAll]', error)
    }
  },
  updateVoucher({ commit }, { value, router }: VoucherPayload) {
    updateHistory(router, { voucher: value ? 'true' : 'false' })

    commit('UPDATE_VOUCHER', value)
    commit('FILTER')
  },
  updateCompanySize({ commit }, { value, router }: CompanySizePayload) {
    updateHistory(router, { company: value ? value : '' })

    commit('UPDATE_COMPANY_SIZE', value)
    commit('FILTER')
  },
  updateProfile({ commit }, { value, router }: ProfilePayload) {
    updateHistory(router, { profile: value })

    commit('UPDATE_PROFILE', value)
    commit('FILTER')
  },
  updateKeywords({ commit }, { value, router }: KeywordsPayload) {
    updateHistory(router, { keywords: value })

    commit('UPDATE_KEYWORDS', value)
    commit('FILTER')
  },
  updateCategories(
    { commit, state, getters, dispatch },
    { value, router }: CategoriesPayload
  ) {
    const categorySlugs = value || []
    updateHistory(router, { categories: categorySlugs.join(',') })

    if (state.profile) {
      // CessionAcquisition = 'buyers' | 'sellers' (aka profile) keys
      commit('UPDATE_FILTER', {
        key: state.profile,
        selection: categorySlugs,
      })
    } else {
      dispatch('updateComplexCategories', {
        slugs: categorySlugs,
        items: getters.categories.items,
      })
    }

    commit('FILTER')
  },
  updateComplexCategories(
    { commit },
    { items, slugs }: { items: CatalogLabelSlug[]; slugs: string[] }
  ) {
    items.forEach(item => {
      // TODO: do not mutate if not needed (already empty or full selected)
      const selection = slugs.includes(item.slug) ? 'all' : 'none'

      commit('UPDATE_FILTER', {
        key: 'categories',
        itemSlug: item.slug,
        selection,
      })
    })
  },
  updateFilter({ commit, state }, payload: FilterPayload) {
    if (state.filters) {
      const { router, key, selection } = payload
      const filter = state.filters[key] as FilterSimple | FilterComplex

      // REVIEW: centralize query string management
      if (filter.kind === 'simple' && ['sectors', 'regions'].includes(key)) {
        const values = Array.isArray(selection) ? selection : [selection]

        updateHistory(router, { [key]: values.join(',') })
      }

      commit('UPDATE_FILTER', payload)
      commit('FILTER')
    }
  },
  resetFilter(
    { commit },
    payload: {
      key: string
      itemSlug?: string
    }
  ) {
    ;(payload as FilterPayload).selection = 'none'

    commit('UPDATE_FILTER', payload)
    commit('FILTER')
  },
  resetAllFilters({ commit, state }, noFilter = false) {
    if (state.filters) {
      Object.keys(state.filters).forEach(key => {
        commit('UPDATE_FILTER', { key, selection: 'none' })
      })
      noFilter && commit('FILTER')
    }
  },
  resetAll(
    { commit, dispatch, state },
    { segment: _segment, router }: ResetAllPayload
  ) {
    const segment = _segment || defaultValues.segment

    updateHistory(router, {})

    commit('UPDATE_COMPANY_SIZE', defaultValues.companySize)
    commit('UPDATE_VOUCHER', defaultValues.hasVoucher)
    if (segment === 'cession-acquisition') {
      commit('UPDATE_PROFILE', defaultValues.profile[segment])
    }
    commit('UPDATE_KEYWORDS', defaultValues.keywords)
    dispatch('resetAllFilters', true)

    state.selectedKeywords = defaultValues.selectedKeywords

    commit('FILTER')
  },
  toggleView({ commit, state }, { value, router }: ViewPayload) {
    const view = value || (state.view === 'map' ? 'list' : 'map')

    updateHistory(router, {
      view,
    })
    commit('SET_VIEW', view)
  },
  setMarkers({ commit }) {
    commit('SET_MARKERS', true)
    // Extra filtering to avoid markers to be displayed
    // in addition to clusters
    commit('FILTER')
  },
}

export const catalog: Module<CatalogState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
