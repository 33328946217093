





























import { defineComponent } from '@vue/composition-api'
import { Btn } from '@/inc/types'

export default defineComponent({
  props: {
    content: {
      type: Object as () => Btn,
      default() {
        return {} as Btn
      },
    },
  },
  name: 'g-btn',
  setup(props) {
    console.log(props.content)

    return {}
  },
})
