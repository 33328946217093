





































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import gsap from 'gsap'

// eslint-disable-next-line

import ChromeHeader from '@/components/chrome/Header.vue'
// eslint-disable-next-line capitalized-comments
// import ChromeFooter from '@/components/chrome/Footer.vue'
import EnvSwitcher from '@/components/EnvSwitcher.vue'

import NotFound from '@/views/NotFound.vue'

import { StorageUtils } from '@/inc/plugins/storage'
import { isUnderMaintenance } from '@/inc/app.config'
import favicon from '@/inc/custom/favicon'

export default Vue.extend({
  name: 'app',
  components: {
    // ChromeNav,
    ChromeHeader,
    // ChromeFooter,
    NotFound,
    EnvSwitcher,
  },
  data() {
    return {
      hasError: false,
      isUnderMaintenance,
      body: {} as HTMLElement,
    }
  },
  computed: {
    ...mapGetters(['meta', 'chrome', 'template', 'content', 'segment']),
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
      setSegment: 'SET_SEGMENT',
    }),
    ...mapActions(['fetchChrome']),
    leave(el, done) {
      document.querySelector('html')!.classList.add('scroll-auto')
      // this.$refs.transition.classList.add(this.content.segment || 'groupe')
      const tl = gsap.timeline()
      tl.set(this.$refs.transition, { left: '-20%', width: 0 })
      tl.to(this.$refs.transition, {
        width: '150%',
        duration: 0.9,
        ease: 'expo.in',
      })
      tl.add(() => done())
    },
    enter(el, done) {
      this.setSegment(this.$route.params.segment)

      const tl = gsap.timeline({
        onComplete: () => {
          // this.$refs.transition.classList.remove(this.content.segment)
        },
      })
      tl.set(this.$refs.transition, { left: '-20%', right: 0 })
      tl.to(this.$refs.transition, {
        left: '120%',
        duration: 1.5,
        ease: 'power4.out',
      })
      tl.add(() => done(), '-=1')
      document.querySelector('html')!.classList.remove('scroll-auto')
    },
    getSegment() {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      return (
        (this as any).content?.segment ||
        (this as any).$route.params.segment ||
        'groupe'
      )
      /* eslint-enable @typescript-eslint/no-explicit-any */
    },
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  async created() {
    this.$logger.trace('[app:created]', this.$$type)
    if (!this.$isServer) {
      this.hasError =
        document.querySelector('[data-server-rendered].error') !== null
    }

    if (this.hasError) {
      return
    }

    // Pass option to root context…
    // More convenient because $options.$storage could be undefined
    this.$root.$storage = this.$root.$options.$storage as StorageUtils

    await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })

    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      this.setResource(this.$router.$resource)
    }
  },
  mounted() {
    this.$logger.trace('[app:mounted]')
    this.body = document.body
    this.setSegment(this.$route.params.segment)
  },
  watch: {
    $route() {
      // Update store
      this.setResource(this.$router.$resource)
    },
  },
  head() {
    return {
      ...this.meta,
      link: favicon?.link.concat(this.meta?.link || []),
      meta: favicon?.meta.concat(this.meta?.meta || []),
    }
  },
})
