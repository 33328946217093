











































import { defineComponent } from '@vue/composition-api'
import { VideoCard } from '@/inc/types'

export default defineComponent({
  name: 'GVideoCard',
  props: {
    content: {
      type: Object as () => VideoCard,
      required: true,
    },
  },
  setup(props, ctx) {
    const openModal = () => {
      ctx.emit('video:open', props.content.youtubeId)
    }

    return {
      openModal,
    }
  },
})
