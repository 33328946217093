import Vue from 'vue'
import VueRouter from 'vue-router'
import { Store } from 'vuex'

import { routes } from './routes'
import { guards } from './guards'
import { RootState } from '@/inc/types'

Vue.use(VueRouter)

type Position = { x: number; y: number }
type PositionResult =
  | Position
  | { selector: string; offset?: Position; behavior?: ScrollBehavior }
  | void

export default function createRouter(store: Store<RootState>): VueRouter {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, _from, savedPosition) {
      // Full example: https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
      // Can return:
      // - "falsy" (null | void | {})
      // - { x: number, y: number }
      // - { selector: string, offset?: { x: number, y: number } }
      // - Promise (e.g. waiting for event/transition)

      if (savedPosition) {
        // SavedPosition is only available for popstate navigations.
        return savedPosition
      }

      // Manage scroll to anchor
      const scrollToAnchor = (hash: string): PositionResult => {
        if (document.querySelector(hash)) {
          return {
            selector: hash,
            behavior: 'smooth',
          }
        }

        return { x: 0, y: 0 }
      }

      if (to.hash) {
        if (to.path === _from.path) {
          return scrollToAnchor(to.hash)
        }

        // If we change pages, wait for the transition to be done
        return new Promise(resolve => {
          router.app.$root.$once('transition:done', () => {
            resolve(scrollToAnchor(to.hash))
          })
        })
      }

      // Check if any matched route config has meta that requires scrolling to top
      // Coords will be used if no selector is provided,
      // or if the selector didn't match any element.

      // Default: scroll to top
      if (to.matched.some(m => m.meta.scrollTop === false)) {
        return null
      }

      return new Promise(resolve => {
        router.app.$root.$once('transition:middle', () => {
          resolve({
            x: 0,
            y: 0,
            behavior: 'auto',
          })
        })
      })
    },
  })

  guards(router, store)

  return router
}
