interface OutsideItem {
  binding: {
    // eslint-disable-next-line @typescript-eslint/ban-types
    action: Function
    isOpen: boolean
  }
  el: HTMLElement
}

const buffer = 100
const items: OutsideItem[] = []
let hasListener = false

const closeLast = () => {
  const lastItem = items.pop()
  lastItem?.binding.action()
}

const clickHandler = (e: MouseEvent): void => {
  // First close the latest open item
  const latest = items[items.length - 1]

  if (!latest || latest.el.contains(e.target as Node)) {
    return
  }

  closeLast()
}
const keyupHandler = (e: KeyboardEvent): void => {
  if (e.key !== 'Escape') {
    return
  }

  closeLast()
}

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bind(el: HTMLElement, binding: any) {
    if (!hasListener) {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keyup', keyupHandler)

      hasListener = true
    }

    // Let initial click trigger
    // (otherwise the element closes immediately)
    setTimeout(() => {
      if (binding.value.isOpen) {
        items.push({ binding: binding.value, el })
      }
    }, buffer)
  },
  unbind() {
    // If a `v-outside` triggers the closing of another,
    // wait for the new item was added
    setTimeout(() => {
      if (items.length === 0) {
        document.removeEventListener('click', clickHandler)
        document.removeEventListener('keyup', keyupHandler)

        hasListener = false
      }
    }, buffer)
  },
}
