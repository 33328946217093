import { Language } from '@/inc/types'

/**
 * Languages
 */
export const langDefault: Language = 'fr'
export const langAvailable = ['fr'] as const

/**
 * URLs, segments, endpoints
 */
export const segments = {
  fundings: 'liste-financements',
  funding: 'financement',
  category: 'categorie',
  segment: 'segment',
} as Record<string, string | string[]>
export const endpointDefault = 'pages'

// API URLs
export const getApiUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`
export const getThumborUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_THUMBOR_PATH}`

/**
 * GTM IDs
 */
export const gtm = {
  production: 'GTM-PWSPWTN',
  staging: 'GTM-MLK7RXZ',
}

/**
 * Options
 */
export const isUnderMaintenance = false
export const useCookiebar = true
export const favicon = {
  tabColor: '#d5af48',
  tileColor: '#d5af48',
  themeColor: '#ffffff',
}
