
































































































import { defineComponent } from '@vue/composition-api'
import {
  Card,
  PersonCard,
  FileCard,
  FundingCard,
  InvestCard,
  NewsCard,
  VideoCard,
} from '@/inc/types'

type CardIcons = Record<string, string>

export default defineComponent({
  name: 'GCard',
  components: {},
  props: {
    content: {
      type: Object as () =>
        | Card
        | PersonCard
        | FileCard
        | FundingCard
        | InvestCard
        | NewsCard
        | VideoCard,
      default() {
        return {} as
          | Card
          | PersonCard
          | FileCard
          | FundingCard
          | InvestCard
          | NewsCard
          | VideoCard
      },
    },
    heading: {
      type: String,
      default: 'div',
    },
    modifiers: Array,
  },
  setup(props) {
    const cardIcons: CardIcons = {
      external: 'icon-external-link',
      fileCard: 'icon-download',
      investCard: 'icon-external-link',
      jobCard: 'icon-see-more',
      simpleCard: 'icon-see-more',
      fundingCard: 'icon-see-more',
      newsCard: 'icon-see-more',
      videoCard: 'icon-camera',
      pageCard: 'icon-arrow-right-light',
    }

    const typeLabels: Record<string, string> = {
      fileCard: 'fichier',
      investCard: 'partenaire',
      jobCard: 'recrutement',
      fundingCard: 'produit',
      newsCard: 'article',
      videoCard: 'témoignage',
      pageCard: 'page',
    }

    const isLink = props.content.url || props.content.link
    const isVideo = props.content.youtubeId
    const url = isLink ? props.content.url || props.content.link.url : null
    const target = props.content.type === 'fileCard' ? '_blank' : null
    let tag: string | null = null

    if (isLink) {
      tag = target === '_blank' ? 'a' : 'router-link'
    } else {
      tag = 'div'
    }

    const renderDate = (date: number) => {
      const formatter = new Intl.DateTimeFormat('fr-be', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
      })

      const formatted = formatter.formatToParts(date)

      return `${formatted[0].value}.${formatted[2].value}.${formatted[4].value}`
    }

    return {
      tag,
      url,
      target,
      isLink,
      isVideo,
      cardIcons,
      typeLabels,
      renderDate,
    }
  },
})
