
















































/* eslint-disable capitalized-comments */
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'
// import HeaderMenu from '@/components/chrome/header/Menu.vue'
// import HeaderLogo from '@/components/chrome/header/Logo.vue'
// import HeaderSearch from '@/components/chrome/header/Search.vue'
import gsap from 'gsap'

export default defineComponent({
  name: 'ChromeHeader',
  components: {
    // HeaderMenu,
    // HeaderLogo,
    // HeaderSearch,
  },
  setup(props, ctx) {
    const { isMenuOpen } = useGetters(['isMenuOpen'])
    const { TOGGLE_MENU: toggleMenu, CLOSE_MENU: closeMenu } = useMutations([
      'TOGGLE_MENU',
      'CLOSE_MENU',
    ])
    const yPosition = ref(0)
    const goingUp = ref(false)
    const isMobile = ref(true)
    const isMounted = ref(false)
    const backdrop = ref<HTMLDivElement | null>(null)
    const menu = ref()

    const toggle = () => {
      if (isMenuOpen.value) {
        close()
      } else {
        const tl = gsap.timeline({
          onComplete: () => {
            gsap.set([backdrop.value, menu.value.$el], {
              clearProps: 'all',
            })
            toggleMenu()
          },
        })

        tl.set([backdrop.value, menu.value.$el], {
          display: 'block',
          overflow: 'hidden',
        })
        tl.from(backdrop.value, { opacity: 0, duration: 0.3 })
        tl.from(menu.value.$el, { height: 0, duration: 0.5 })
      }
    }

    const close = () => {
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([backdrop.value, menu.value.$el], {
            clearProps: 'all',
          })
          closeMenu()
        },
      })

      tl.set([backdrop.value, menu.value.$el], {
        overflow: 'hidden',
      })
      tl.to(menu.value.$el, { height: 0, duration: 0.3 })
      tl.to(backdrop.value, { opacity: 0, duration: 0.3 })
    }

    onMounted(() => {
      isMobile.value = window.innerWidth < 960
      isMounted.value = true
      ctx.root.$router.beforeEach((to, from, next) => {
        if (isMenuOpen.value) {
          closeMenu()
        }

        next()
      })
    })

    const onScroll = (x: number, y: number) => {
      goingUp.value = y < yPosition.value
      yPosition.value = y
    }

    const onResize = (width: number) => {
      if (width >= 960) {
        isMobile.value = false

        if (isMenuOpen.value) {
          closeMenu()
        }
      } else {
        isMobile.value = true
      }
    }

    return {
      ...useGetters(['chrome', 'content', 'segment']),
      onScroll,
      onResize,
      yPosition,
      goingUp,
      isMenuOpen,
      isMobile,
      isMounted,
      toggle,
      closeMenu,
      backdrop,
      menu,
    }
  },
})
