




import { computed, defineComponent } from '@vue/composition-api'
import { FlexibleComponent } from '@/inc/types'

export default defineComponent({
  name: 'GWysiwyg',
  props: {
    wysiwyg: {
      type: String,
      default: '',
    },
    content: {
      type: Object as () => FlexibleComponent,
      default() {
        return {} as FlexibleComponent
      },
    },
  },
  setup(props) {
    const runtimeComponent = computed(() => ({
      components: {},
      template: `<div>${
        props.wysiwyg || props.content?.htmltext || props.content?.text
      }</div>`,
    }))

    return {
      runtimeComponent,
    }
  },
})
