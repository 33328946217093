import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState, UiState } from '@/inc/types'

const state: UiState = {
  isMenuOpen: false,
  segment: 'groupe',
  onboarded: false,
}

const getters: GetterTree<UiState, RootState> = {
  isMenuOpen: state => state.isMenuOpen,
  segment: state => state.segment,
  onboarded: state => state.onboarded,
}

const mutations: MutationTree<UiState> = {
  OPEN_MENU(state) {
    state.isMenuOpen = true
    document.body.classList.add('no-scroll')
  },
  CLOSE_MENU(state) {
    state.isMenuOpen = false
    document.body.classList.remove('no-scroll')
  },
  TOGGLE_MENU(state) {
    state.isMenuOpen = !state.isMenuOpen

    if (state.isMenuOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
  },
  SET_SEGMENT(state, segment: string) {
    state.segment = segment
  },
  SET_ONBOARDED(state) {
    state.onboarded = true
  },
}

const actions: ActionTree<UiState, RootState> = {}

export const ui = {
  state,
  getters,
  mutations,
  actions,
}
