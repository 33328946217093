












import { defineComponent } from '@vue/composition-api'
import { Segment } from '@/inc/types'

export default defineComponent({
  name: 'GTags',
  props: {
    tags: {
      type: Array || (Object as () => Segment),
      required: true,
    },
  },
  setup() {
    return {}
  },
})
