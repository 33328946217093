// Webpack imports
import '@/styles/main.scss'

import Vue from 'vue'
import axios from 'axios'

// Plugins
// import Fragment from 'vue-fragment'
// Vue.use(Fragment.Plugin)
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import hooks from '@u3u/vue-hooks'
Vue.use(hooks)

import VueAware from 'vue-aware'
Vue.use(VueAware)

import VueSvgSprite from 'vue-svg-sprite'
Vue.use(VueSvgSprite, { url: '' })

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

// Components

// Directives
import VueOutside from '@/inc/directives/outside'
Vue.directive('outside', VueOutside)
import VueSrc from '@/inc/directives/src'
Vue.directive('src', VueSrc)

// Filters
import VFilters, {
  VFiltersOptions,
  capitalize,
  linebreak,
  modifiers,
  slugify,
  trimzeros,
} from '@monofront/v-filters'

Vue.use<VFiltersOptions>(VFilters, {
  capitalize,
  linebreak,
  modifiers,
  slugify,
  trimzeros,
})

import i18n from '@/inc/i18n'
import { App, AppType } from '@/inc/types'
import { logger, pascalToKebab } from '@/inc/utils'
// DEV
// import AppComponent from '@/App.vue'
// import ErrorComponent from '@/Error.vue'
// import createRouter from '@/router'
// import createStore from '@/store'

logger.info(process.env)

Vue.prototype.$logger = logger
Vue.prototype.$debug = false
Vue.config.productionTip = false

// Import all global components
// Btn.vue => <g-btn></g-btn>
const requireComponent = require.context('@/components/g', true, /.*\.vue$/)

requireComponent.keys().forEach(fileName => {
  let baseComponentConfig = requireComponent(fileName)

  baseComponentConfig = baseComponentConfig.default || baseComponentConfig

  const baseComponentName =
    baseComponentConfig.name ||
    `g-${pascalToKebab(fileName.replace(/^.+\//, '').replace(/\.\w+$/, ''))}`

  Vue.component(baseComponentName, baseComponentConfig)
})

// Export factory function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function createApp(ctx: any): App {
  const { type } = ctx as { type: AppType }

  logger.trace('[main:createApp]', type)

  // Set default base URL
  if (type === 'client') {
    axios.defaults.baseURL = window.location.origin
    Vue.prototype.$debug = window.location.search.includes('debug')
  }

  axios.defaults.withCredentials = true

  /* eslint-disable @typescript-eslint/no-var-requires, global-require */
  const AppComponent = require('@/App.vue').default
  const ErrorComponent = require('@/Error.vue').default
  const createRouter = require('@/router').default
  const createStore = require('@/store').default
  /* eslint-enable @typescript-eslint/no-var-requires, global-require */

  const store = createStore(type)
  const router = createRouter(store)
  const app = {
    ErrorComponent,
    i18n,
    router,
    store,
    // This is necessary, it is for vue-meta
    head: {
      titleTemplate: '%s',
    },
    // This will expose `$root.$options.$resource` into components (server-side)
    $resource: ctx.$resource,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (h: any) => h(AppComponent),
  }

  return app
}
