




















import { defineComponent } from '@vue/composition-api'
import { Header } from '@/inc/types'

export default defineComponent({
  name: 'GHeader',
  props: {
    content: {
      type: Object as () => Header,
      default() {
        return {} as Header
      },
    },
    isMainHeader: {
      type: Boolean,
      default: true,
    },
  },
  setup(_props, ctx) {
    const route = ctx.root.$route
    const { segment } = route.params

    return {
      segment,
    }
  },
})
