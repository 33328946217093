













import { defineComponent } from '@vue/composition-api'
import { CardList } from '@/inc/types'

export default defineComponent({
  name: 'GCardList',
  components: {},
  props: {
    cards: {
      type: Array as () => CardList,
      default() {
        return [] as CardList
      },
    },
  },
  setup() {
    return {}
  },
})
