import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState, FundingsState } from '@/inc/types'

const state: FundingsState = {
  fundingCategory: '',
  fundingSegment: '',
}

const getters: GetterTree<FundingsState, RootState> = {
  fundingCategory: state => state.fundingCategory,
  fundingSegment: state => state.fundingSegment,
}

const mutations: MutationTree<FundingsState> = {
  SET_FUNDING_CATEGORY(state, category: string) {
    state.fundingCategory = category
  },
  SET_FUNDING_SEGMENT(state, segment: string) {
    state.fundingSegment = segment
  },
}

const actions: ActionTree<FundingsState, RootState> = {}

export const fundings = {
  state,
  getters,
  mutations,
  actions,
}
